export class Translate {
  constructor(func) {
    this.translate = func
  }

  static create(func, force = false) {
    if (!Translate.instance || force) {
      Translate.instance = new Translate(func)
    }
  }

  static getInstance() {
    /*  if (!Translate.instance) {
      return new Translate()
    } */

    return Translate.instance
  }
}
