import dayjs from 'dayjs'
import { Translate } from '@common/Basic/translate'

export default function getBillDate(countryCode) {
  const today = new Date().getDate()
  let monthBillDate, addMthCount

  switch (countryCode) {
    case 'MY':
      monthBillDate = 7
      addMthCount = today > 20 ? 2 : 1
      break
    case 'PH':
      monthBillDate = 15
      // addMthCount = today > 20 ? 2 : 1
      addMthCount = 1
      break
    case 'VN':
      monthBillDate = 7
      addMthCount = today > 25 ? 2 : 1
      break
    case 'ID':
    default:
      monthBillDate = 25
      addMthCount = today > 10 ? 1 : 0
      break
  }
  return {
    monthBillDate,
    addMthCount,
  }
}

//#region 还款日引导
// 还款日列表
export const getRepaymentDates = () => {
  const translate = Translate.getInstance().translate
  return [
    { label: translate('每月2日'), value: 2 },
    { label: translate('每月10日'), value: 10 },
    { label: translate('每月25日'), value: 25 },
  ]
}

/**
 * 获取下一还款日
 * @param selectedDate 用户选择还款日
 * @description 下一还款日计算规则：https://www.tapd.cn/62937117/prong/stories/view/1162937117001071776
 */
export function getNextRepaymentDate(selectedDate) {
  const current = dayjs()
  const currentDay = current.date()
  const map = {
    2: () => {
      return current.add(currentDay < 18 ? 1 : 2, 'M')
    },
    10: () => {
      return current.add(currentDay < 26 ? 1 : 2, 'M')
    },
    25: () => {
      return currentDay < 11 ? current : current.add(1, 'M')
    },
  }
  if (map[selectedDate]) {
    return map[selectedDate]().date(selectedDate).format('YYYY/MM/DD')
  }
}
//#endregion
