/*
 * 通过此文件，在全工程级别，唯一的推导出使用语言
 */

/* eslint no-use-before-define: off */

import JsCookie from 'js-cookie'
import { isString, URL } from '../../../helpers'
/* import { isAkulaku } from '../../is'
import jsBridge from '../../jsBridge' */

function getUrlParam(key) {
  return URL.param(key)
}

let cachedI18n

/**
 * [detectI18n 获取基础信息（国家码，语言码，货币单位）]
 * @return {[Promise]}              [Promise的成功回调接受获取结果]
 */
function detectI18n() {
  if (cachedI18n) {
    // 缓存结果，页面实例生命周期内不再重复计算
    return Promise.resolve(cachedI18n)
  }
  return new Promise(async (resolve, reject) => {
    try {
      const countryCode = await detectCountryCode()
      // TODO HACK 工程历史实现时序错误或不准确，人为标记已完成推导以便外部检查
      // TODO HACK 见 ajax 相关行为
      window['is-detected-countryCode-zKJHiTMC2iGnHOwx'] = true
      window['i18n-countryCode-zKJHiTMC2iGnHOwx'] = countryCode
      let i18n = normalizeI18n(countryCode)
      const languageCode = await detectLanguageCode(i18n)
      i18n.languageCode = languageCode
      cachedI18n = i18n
      resolve(i18n)
    } catch (err) {
      reject(err)
    }
  })
}

// http://wiki.al.com/pages/viewpage.action?pageId=8720076
// 1. 从 URL 推导
// 2. 从 cookie 推导
// 3. 从 bridge 推导
async function detectCountryCode() {
  // url 推导
  let urlCountryCode =
    getUrlParam('lang') ||
    getUrlParam('countryCode') ||
    getUrlParam('_redirectCountryCode')

  // urlCountryCode = urlCountryCode.toUpperCase()

  // 现在没有从url里面去获取countryCode的场景了
  // if (!urlCountryCode) {
  //   location.hostname.replace(/^([a-z]{2})-/i, ($0, $1) => {
  //     urlCountryCode = $1
  //   })
  // }

  if (urlCountryCode) {
    return returnAndVerifyCookie(urlCountryCode)
  }
  /*  if (isValidCountryCode(urlCountryCode)) {
    return returnAndVerifyCookie(urlCountryCode)
  } */

  // cookie 推导
  const cookieCountryCode = JsCookie.get('countryCode')
  if (cookieCountryCode) {
    return returnAndVerifyCookie(cookieCountryCode)
  }

  // url / cookie 均不存在时，从 native 获取
  /* const nativeCountryCode = await loadCountryCodeFromNative()
  if (isValidCountryCode(nativeCountryCode)) {
    return returnAndVerifyCookie(nativeCountryCode)
  } */

  // 无法推导，使用默认值
  console.warn('无法推导 countryCode，使用默认值')
  return returnAndVerifyCookie('ID')

  function returnAndVerifyCookie(targetCountryCode) {
    const result = targetCountryCode.toUpperCase()
    // 本地写入成本低，不再考虑与 cookie 内的现有值比对变化，总是更新
    JsCookie.set('countryCode', result)
    return result
  }

  /* function isValidCountryCode(code) {
    return ['ID', 'MY', 'VN', 'PH'].indexOf(code) !== -1
  } */

  /*  function loadCountryCodeFromNative() {
    return new Promise((resolve, reject) => {
      if (isAkulaku() && jsBridge.getBasicInfo.isSupported()) {
        jsBridge.getBasicInfo(info => {
          if (isString(info)) {
            try {
              info = JSON.parse(info)
            } catch (err) {
              reject(err)
            }
          }
          resolve(info.countryCode)
        })
      } else {
        resolve(undefined)
      }
    })
  } */
}

//  languageCode 推导
async function detectLanguageCode(i18n) {
  // url 推导
  let urlLanguageCode = getUrlParam('languageCode')
  let countryCode = getUrlParam('lang')

  //如果只有国家码，没有语言码，则使用国家码推导
  if (countryCode && !urlLanguageCode) {
    console.warn('无法推导 languageCode，使用countryCode推导')
    return returnAndVerifyCookie(i18n.languageCode)
  }

  //如果既没有语言码也没有国家码
  // 现在没有从url里面去获取countryCode的场景了
  // if (!urlLanguageCode && !countryCode) {
  //   location.hostname.replace(/^([a-z]{2})-/i, ($0, $1) => {
  //     urlLanguageCode = $1
  //   })
  // }

  if (urlLanguageCode) {
    return returnAndVerifyCookie(urlLanguageCode)
  }

  // cookie 推导
  const cookieCountryCode = JsCookie.get('languageCode')
  if (cookieCountryCode) {
    return returnAndVerifyCookie(cookieCountryCode)
  }

  // url / cookie 均不存在时，从 native 获取
  /*   const nativeLanguageCode = await loadLanguageCodeFromNative()
  if (nativeLanguageCode) {
    return returnAndVerifyCookie(nativeLanguageCode)
  } */

  // 无法推导，使用默认值
  console.warn('无法推导 languageCode，使用countryCode推导')
  return returnAndVerifyCookie(i18n.languageCode)

  function returnAndVerifyCookie(targetCountryCode) {
    const result = targetCountryCode.toUpperCase()
    // 本地写入成本低，不再考虑与 cookie 内的现有值比对变化，总是更新
    JsCookie.set('languageCode', result, {
      domain: '.akulaku.com',
    })
    return result
  }

  /* function loadLanguageCodeFromNative() {
    return new Promise((resolve, reject) => {
      if (isAkulaku() && jsBridge.getBasicInfo.isSupported()) {
        jsBridge.getBasicInfo(info => {
          if (isString(info)) {
            try {
              info = JSON.parse(info)
            } catch (err) {
              reject(err)
            }
          }
          resolve(info.languageCode)
        })
      } else {
        resolve(undefined)
      }
    })
  } */
}

// 从 countryCode 推导完整语言配置
function normalizeI18n(countryCode) {
  let countryId
  let languageCode
  let currencyCode
  let areaId = getUrlParam('areaId') || '1'
  switch (typeof countryCode === 'string' && countryCode.toUpperCase()) {
    case 'ID':
      countryId = 1
      languageCode = 'IN'
      currencyCode = 'Rp'
      break
    case 'MY':
      countryId = 2
      languageCode = 'MS'
      currencyCode = 'RM'
      break
    case 'PH':
      countryId = 3
      languageCode = 'EN'
      currencyCode = '₱'
      break
    case 'VN':
      countryId = 4
      languageCode = 'VI'
      currencyCode = '₫'
      break
    default:
      throw new Error(`unsupported countryCode(${countryCode})`)
  }
  return {
    countryId,
    countryCode,
    languageCode,
    currencyCode,
    areaId,
  }
}

// TODO HACK 尽可能早确认 i18n，但不能保证业务代码前已完成确认
// 业务上，存在先查询接口再确认 i18n 的情况
// 由于，已明确页面生命周期内，detectI18n 返回值为常量；故，提前自执行代码，使得在业务代码和绝大多数全局代码前，已确认 i18n
// 然而，无法声明异步模块，无法阻塞代码执行
const task = detectI18n()

export default function () {
  // 异步锁，防止重复启动任务
  return task
}
