import React, { Component } from 'react'

import * as helpers from '../helpers'

// import Loading from './components/Loading'
import CacheComponent from './components/CacheComponent'

const { isExist } = helpers

export const asyncComponent = (importComponent) =>
  class AsyncComponent extends Component {
    state = {}

    async componentDidMount() {
      const { default: Component } = await importComponent()

      this.setState({ Component })
    }

    render() {
      const { Component } = this.state
      return isExist(Component) ? <Component {...this.props} /> : null
    }
  }

export const cacheComponent = (Component) => (props) =>
  (
    <CacheComponent {...props}>
      {(cacheLifecycles) => <Component {...props} {...{ cacheLifecycles }} />}
    </CacheComponent>
  )
