import { isExist } from '../helpers/is'

const objFilter = (obj, keys = Object.keys(obj)) =>
  Object.entries(obj)
    .filter(([key, value]) => keys.includes(key) && isExist(value))
    .reduce(
      (res, [key, value]) =>
        Object.assign(res, {
          [key]: value,
        }),
      {},
    )

const __country__info__json = [
  {
    phoneMaxLength: 13,
    shippingCost: 0,
    phoneCountryCode: '62',
    countryCode: 'ID',
    languageId: 123,
    id: 1,
    countryName: 'Indonesia',
    languageCode: 'IN',
    currencyId: 4,
    currencyCode: 'Rp',
  },
  {
    phoneMaxLength: 11,
    shippingCost: 20,
    phoneCountryCode: '60',
    countryCode: 'MY',
    languageId: 102,
    id: 2,
    countryName: 'Malaysia',
    languageCode: 'EN',
    currencyId: 3,
    currencyCode: 'RM',
  },
  {
    phoneMaxLength: 11,
    shippingCost: 1,
    phoneCountryCode: '63',
    countryCode: 'PH',
    languageId: 102,
    id: 3,
    countryName: 'Philippines',
    languageCode: 'EN',
    currencyId: 5,
    currencyCode: '₱',
  },
  {
    phoneMaxLength: 11,
    shippingCost: 0,
    phoneCountryCode: '84',
    countryCode: 'VN',
    languageId: 142,
    id: 4,
    countryName: 'Vietnam',
    languageCode: 'VI',
    currencyId: 6,
    currencyCode: '₫',
  },
]

export default __country__info__json.reduce(
  (res, info) =>
    Object.assign(res, {
      [info.countryCode]: objFilter(
        Object.assign({}, info, {
          countryId: info.id,
        }),
        ['countryId', 'languageId', 'currencyId'],
      ),
    }),
  {},
)
