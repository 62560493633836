import axios from 'axios'
import { storage, isUndefined, get } from '../../helpers'

// 域名黑名单，处于黑名单内的域名无法请求用户详情接口
const __host__black__list = [
  // 现金贷域名
  'testweb.vithantai.com',
  'web.vithantai.com',
]

const host = window.location.host

export const getUserInfo = async () => {
  if (__host__black__list.includes(host)) {
    return
  }
  // 由于/installment/api/json/user/detail.json 线上环境频繁报错，并且这个请求是OP业务没使用到的历史遗留的冗余请求，暂时注释掉
  // const response = await axios.get('/installment/api/json/user/detail.json', {
  //   headers: {
  //     dt: 7,
  //     vc: 0,
  //   },
  // })

  // return get(response, 'data.data')
}

const __storage__key = 'akulaku:userInfo'
export const updateUserInfo = async () => {
  let userInfo

  try {
    userInfo = await getUserInfo()

    storage.set(__storage__key, userInfo)
  } catch (err) {
    storage.remove(__storage__key)
  } finally {
    return userInfo
  }
}

export const checkIsOnline = async () => {
  let userInfo = await updateUserInfo()

  if (isUndefined(userInfo)) {
    throw 'Unlogin'
  } else {
    return userInfo
  }
}
