import { storage } from '../../../helpers'
import detectI18n from './_detectI18n'

// 原实现有参数 { fromNative = false, saveToStorage = true, mergeFromUrl = true }
// 但国家语言信息其实是常量，不应当不同调用方法计算出不同结果
// 同时，整体时序需要尽可能早的确认 i18n，必须支持无副作用的提前执行
export default async function getBasicInfo() {
  const i18n = await detectI18n()

  // 保持历史逻辑
  // 业务场景不要再使用
  const basicInfo = i18n
  storage.set('akulaku:basicInfo', basicInfo)
  return basicInfo
}
