import React, { Component, useContext } from 'react'
import hoistStatics from 'hoist-non-react-statics'

// import { akulaku, helpers } from '@common'
import * as akulaku from '../akulaku'
import * as helpers from '../helpers'
import { Translate } from './translate'
import _ from 'lodash'
import __country__info from './__country__info'

const { get, value, run } = helpers
const BasicContext = React.createContext()
const { Provider, Consumer } = BasicContext

export function useBasicContext() {
  return useContext(BasicContext)
}

export default class Basic extends Component {
  constructor(props) {
    super(props)

    const translate = helpers.i18n(
      _.isPlainObject(props.locale.in) ? props.locale.in : {},
    )
    Translate.create(translate)

    this.state = {
      areaId: '1',
      countryCode: 'ID',
      countryId: 1,
      currencyCode: 'Rp',
      currencyId: 4,
      languageCode: 'IN',
      languageId: 123,
      formatPrice: this.getFormatPrice(),
      translate,
      forceChildrenUpdateKey: 1,
      changeLanguage: this.changeLanguage,
    }
  }

  componentDidMount() {
    this.init()
  }

  changeLanguage = async (languageCode) => {
    languageCode = languageCode.toUpperCase()

    if (window.location.href.includes('languageCode')) {
      window.location.href = window.location.href.replace(
        /languageCode=.{2}/,
        `languageCode=${languageCode}`,
      )
    } else {
      window.location.href = `${window.location.href}&languageCode=${languageCode}`
    }
  }

  init = async () => {
    const { areaId, ...basicInfo } = await akulaku.getBasicInfo()

    const languageCode = run(
      value(this.props.languageCode, basicInfo.languageCode),
      'toUpperCase',
    )

    const countryCode = run(
      value(this.props.countryCode, basicInfo.countryCode),
      'toUpperCase',
    )

    //Rp才是正确的单位，这里临时处理一下
    if (basicInfo.currencyCode === 'RP') basicInfo.currencyCode = 'Rp'

    const currencyCode = value(this.props.currencyCode, basicInfo.currencyCode)

    const localeMap = value(get(this.props, 'locale'), () => {
      console.warn('[Basic] 需要 locale 属性')
      return {}
    })

    const locale = await run(
      {
        EN: localeMap.en,
        PH: localeMap.en,
        IN: localeMap.in,
        ID: localeMap.in,
        VN: localeMap.vi,
        VI: localeMap.vi,
        MS: localeMap.ms,
      }[languageCode],
    )

    const formatPrice = this.getFormatPrice(currencyCode)

    const translate = helpers.i18n(locale)
    Translate.create(translate, true)

    this.setState({
      ...Object.assign(get(__country__info, countryCode, {}), basicInfo, {
        areaId: Number(areaId) === 0 ? 1 : areaId,
        languageCode,
        countryCode,
        currencyCode,
        translate,
        formatPrice,
        changeLanguage: this.changeLanguage,
      }),
    })
  }

  getFormatPrice(currencyCode = 'Rp') {
    return ['RP', '₫'].includes(run(currencyCode, 'toUpperCase'))
      ? helpers.getFormatter({
          separator: '.',
        })
      : (_) => _
  }

  render() {
    return (
      <Provider
        value={this.state}
        key={
          this.state.countryCode +
          this.state.currencyCode +
          this.state.languageCode
        }
      >
        {/*{this.state && this.props.children}*/}
        {this.props.children}
      </Provider>
    )
  }
}

export const withBasic = (Component) => {
  const HOC = (props) => (
    <Consumer>{(context) => <Component {...context} {...props} />}</Consumer>
  )

  HOC.displayName = `withBasic(${value(Component.displayName, Component.name)})`

  return hoistStatics(HOC, Component)
}
