import React, { forwardRef } from 'react'
import BridgeV2 from '@akulaku/web-bridge'
import _ from 'lodash'
import { performanceCollection } from '@/__prefix__/performanceReport'
import { isAndroid } from '@common/helpers'

const get = _.get

export default function withPerfCollect(options = {}) {
  return (WrappedComponent) => {
    const name = WrappedComponent.displayName || WrappedComponent.name

    class WithPerfCollect extends React.Component {
      constructor(props, ...rest) {
        super(props, ...rest)
        if (typeof window !== 'undefined' && BridgeV2.isAkulaku()) {
          BridgeV2.getEnvInfoSilent('', (res) => {
            const deviceLevel = get(res, 'data.deviceLevel')
            performanceCollection.setDeviceLevel(
              `${isAndroid() ? 'android' : 'ios'}_${deviceLevel || 'unknown'}`,
            )
          })
        }
      }

      componentDidMount() {
        const { criticalAPI } = options
        let api = criticalAPI
        if (criticalAPI && typeof criticalAPI === 'function') {
          api = criticalAPI(this.props)
        }

        performanceCollection.recordPagePerformance({
          // 这个字段我看sale工程全是true，在这里写个默认值
          needNetResource: true,
          // FIXME: spa工程需要给true，后续升级为SSR工程后需要删掉
          isFirstPage: true,
          ...options,
          criticalAPI: api,
        })
      }

      render() {
        const { forwardedRef, ...props } = this.props
        return <WrappedComponent {...props} ref={forwardedRef} />
      }
    }

    const refForwardedComponent = forwardRef((props, ref) => (
      <WithPerfCollect {...props} forwardedRef={ref} />
    ))
    refForwardedComponent.displayName = `withPerfCollect(${name})`
    return refForwardedComponent
  }
}
